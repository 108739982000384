import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceOneAll = () => {
  return (
    <>
      {/* service area start */}
      <div className='service-area style-2 pd-top-115 pd-bottom-80'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7'>
              <div className='section-title text-center'>
                <h4 className='subtitle'>HİZMETLERİMİZ</h4>
                <h2 className='title'>İNŞAAT VE MARANGOZLUK HİZMETLERİMİZ</h2>
                <p>
                  Building All Care - Marangozluk, inşaat, bungalow ve villa üretimi alanlarında yüksek kaliteli ve özelleştirilmiş çözümler sunuyoruz. İhtiyacınıza özel hizmetler için bizimle iletişime geçin!
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/1.png' alt='Marangozluk Hizmetleri' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-1.png'
                      alt='Marangozluk Hizmetleri'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>Özel Marangozluk Hizmetleri</h5>
                  <p>
                    Kişiye özel marangozluk hizmetlerimizle, ihtiyacınıza uygun özel tasarımlar ve yüksek kaliteli işçilik sunuyoruz.
                  </p>
                  <div className='btn-wrap'>
                    <Link className='read-more-text' to='/marangozluk'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/2.png' alt='İnşaat Hizmetleri' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-2.png'
                      alt='İnşaat Hizmetleri'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>İnşaat ve Renovasyon</h5>
                  <p>
                    Bina inşaatı, renovasyon ve tadilat hizmetlerimizle mekanlarınıza modern ve estetik çözümler getiriyoruz.
                  </p>
                  <div className='btn-wrap'>
                    <Link className='read-more-text' to='/insaat'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/3.png' alt='Bungalow ve Villa Üretimi' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-3.png'
                      alt='Bungalow ve Villa Üretimi'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>Bungalow ve Villa Üretimi</h5>
                  <p>
                    Özel tasarımlı bungalow ve villalarımızla rahat ve konforlu yaşam alanları oluşturuyoruz.
                  </p>
                  <div className='btn-wrap'>
                    <Link className='read-more-text' to='/bungalow-villa'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service area end */}
    </>
  );
};

export default ServiceOneAll;
